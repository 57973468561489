<template>
	<div>
		<v-bottom-sheet v-model="$parent.removeGiftCode" inset>
			<v-sheet class="rounded-sheet" height="230px">
				<div class="px-10 py-7">
					<v-card-title class="pt-0 px-0 pb-3 red--text text--darken-4">
						<v-icon left color="red darken-4">mdi-close</v-icon> Remove Gift Code
					</v-card-title>

					<v-card-text>
						Are you sure about this?
					</v-card-text>

					<v-btn class="mt-2" color="red darken-4 white--text" block depressed @click="removeGiftCode($parent.removeGiftCodeData.gift_code_id)" :loading="removeGiftCodeLoading">Remove</v-btn>
					<v-btn class="mt-4" color="grey darken-4" block outlined @click="$parent.removeGiftCode = false">Nevermind</v-btn>
				</div>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	name: "RemoveGiftCode",
	data: () => ({
		removeGiftCodeLoading: false,
	}),
	methods: {
		removeGiftCode(gift_code_id) {
			/* call the api */
			this.removeGiftCodeLoading = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("gift_code_id", gift_code_id);

			/* make call to server */
			this.$http.post("admin/transactions/removeGiftCode", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.getGiftCodes();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.$parent.removeGiftCode = false;
					this.removeGiftCodeLoading = false;
				})
		}
	}
};
</script>

<style>
.rounded-sheet {
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	overflow: hidden !important;
}
</style> 