<template>
	<div class="d-flex align-center justify-center admin-login" style="height: 100%">

		<v-col class="mx-auto" cols="12" sm="8" md="7" lg="6" xl="5">
			<div class="transparent-white elevation-6 rounded-lg pa-7">
				<h3 class="pt-0 pb-5 text-center">Login To Management Panel</h3>
				<v-form @submit.prevent="loginAdmin()">
					<v-text-field label="Username" prepend-icon="mdi-account" v-model="adminCreds.username"></v-text-field>
					<v-text-field label="Password" type="password" prepend-icon="mdi-key" v-model="adminCreds.password"></v-text-field>
					<v-btn color="primary" class="mt-5" block depressed rounded type="submit" :loading="loading">
						Login To Panel
					</v-btn>
				</v-form>
			</div>

		</v-col>

	</div>
</template>

<script>
export default {
	name: "Login",
	data: () => ({
		loading: false,
		adminCreds: {
			username: '',
			password: ''
		}
	}),
	computed: {
		isDataFilled() {
			if (this.adminCreds.username == '') return false;
			if (this.adminCreds.password == '') return false;
			return true;
		}
	},
	methods: {
		loginAdmin() {
			this.loading = true;
			this.$store.dispatch('loginAdmin', {
				username: this.adminCreds.username,
				password: this.adminCreds.password,
			})
				.then(() => {
					this.$swal.fire({
						icon: "success",
						title: 'Welcome!',
						timer: 1000,
						timerProgressBar: true,
						showConfirmButton: false,
					}).then(() => {
						this.$router.push('/panel/dashboard');
					})
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.loading = false;
				})
		}
	},
	created() {
		if (this.$store.getters.isAdminLoggedIn) {
			this.$router.push('/panel/dashboard');
		}
	}
};
</script>

<style>
.admin-login {
	/* background */
	background-image: url("../assets/pics/bg/login-bg.jpg");
}

.transparent-white {
	background: rgba(255, 255, 255, 0.9) !important;
}
</style>