<template>
	<div>
		<v-col cols="12">
			<v-card elevation="2" :loading="cardLoading">
				<!-- top -->
				<v-card-title class="primary white--text" style="flex-wrap: nowrap;">
					<div>
						Deposits List
					</div>

					<SearchInput v-model="searchQ" @submit="searchDeposits()" />
				</v-card-title>

				<!-- main -->
				<v-simple-table class="text-center text-no-wrap">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-center">#</th>
								<th class="text-center">User id</th>
								<th class="text-center">Amount</th>
								<th class="text-center">Pending Amount</th>
								<th class="text-center">Confirmed Amount</th>
								<th class="text-center">Address</th>
								<th class="text-center">status</th>
								<th class="text-center">Date</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(item , index) in deposits.rows" :key="index">
								<td>{{item.id}}</td>
								<td>{{item.user_id}}</td>
								<td>{{item.amount}}</td>
								<td>{{item.pending_amount}}</td>
								<td>{{item.confirmed_amount}}</td>
								<td>{{item.address}}</td>
								<td>
									<v-chip color="blue darken-2" text-color="white" label x-small v-if="item.status == 'pendingPayment'">
										Pending Payment
									</v-chip>
									<v-chip color="blue darken-2" text-color="white" label x-small v-if="item.status == 'pendingConfirm'">
										Pending Confirm
									</v-chip>
									<v-chip color="green darken-2" text-color="white" label x-small v-if="item.status == 'completed'">
										Completed
									</v-chip>
								</td>
								<td>{{item.human_created_at}}</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<!-- bottom -->
				<v-card-actions class="grey lighten-4">
					<div class="text-center" style="width: 100%;">
						<v-pagination :length="deposits.total_pages" :total-visible="5" v-model="currentPage" @input="getDeposits()"></v-pagination>
					</div>
				</v-card-actions>
			</v-card>
		</v-col>
	</div>
</template>

<script>
import SearchInput from "../../components/SearchInput"

export default {
	name: "Deposits",
	components: {
		SearchInput,
	},
	data: () => ({
		depositsLoading: false,
		deposits: {
			page: 1,
			total_count: 0,
			total_pages: 1,
			rows: []
		},
		currentPage: 1,
		searchQ: '',
		suspendLoading: false,
		suspendLoadingIndex: 0,
	}),
	computed: {
		cardLoading() {
			return this.depositsLoading ? 'blue lighten-3' : false;
		}
	},
	methods: {
		getDeposits() {
			/* call the api */
			this.depositsLoading = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("page", this.currentPage);
			data.append("q", this.searchQ);

			/* make call to server */
			this.$http.post("admin/transactions/getAllDeposits", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.deposits = result.data.data.deposits;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.depositsLoading = false;
				})
		},
		searchDeposits() {
			this.currentPage = 1;
			this.getDeposits();
		},
		suspendDeposit(deposit_id, index, suspend) {
			/* call the api */
			this.suspendLoading = true;
			this.suspendLoadingIndex = index;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("deposit_id", deposit_id);
			data.append("suspend", suspend);

			/* make call to server */
			this.$http.post("admin/deposits/suspendDeposit", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.deposits.rows[index] = result.data.data.deposit;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.suspendLoading = false;
				})
		},
	},
	created() {
		this.getDeposits();
	}
};
</script>

<style>
.pointer {
	cursor: pointer;
}
</style>