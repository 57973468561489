<script>
import { Line } from 'vue-chartjs'

export default {
	extends: Line,
	props: ['data'],
	mounted() {
		var options = {
			maintainAspectRatio: false,
			legend: {
				display: false,
			},
			tooltips: {
				backgroundColor: 'rgba(47, 49, 66, 0.8)',
				titleFontSize: 13,
				titleFontColor: '#fff',
				caretSize: 0,
				cornerRadius: 4,
				xPadding: 10,
				displayColors: false,
				yPadding: 10
			},
			scales: {
				yAxes: [{
					ticks: {
						display: true,
						beginAtZero: true,
						precision: 0
					},
					gridLines: {
						drawBorder: true,
						display: true
					}
				}],
				xAxes: [{
					gridLines: {
						drawBorder: true,
						display: true
					},
					ticks: {
						display: true
					}
				}]
			}
		};
		this.renderChart(this.data, options)
	}
}

</script>