<template>
	<div>
		<v-col cols="12">
			<v-card elevation="2" :loading="cardLoading">
				<!-- top -->
				<v-card-title class="primary white--text" style="flex-wrap: nowrap;">
					<div>
						User Plays List
					</div>

					<SearchInput v-model="searchQ" @submit="searchUserPlays()" />
				</v-card-title>

				<!-- main -->
				<v-simple-table class="text-center text-no-wrap">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-center">#</th>
								<th class="text-center">User</th>
								<th class="text-center">Username</th>
								<th class="text-center">Game</th>
								<th class="text-center">Bet Amount</th>
								<th class="text-center">Win Amount</th>
								<th class="text-center">Status</th>
								<th class="text-center">Selected Numbers</th>
								<th class="text-center">Won Numbers</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(item , index) in userPlays.rows" :key="index">
								<td>{{item.id}}</td>
								<td>#{{item.user_id}}</td>
								<td>{{item.username}}</td>
								<td>#{{item.game_id}}</td>
								<td>{{item.bet_amount}}</td>
								<td>{{item.win_amount}}</td>

								<td>
									<v-chip color="green darken-2" text-color="white" label x-small v-if="item.status == 'processed'">
										Processed
									</v-chip>
									<v-chip color="orange darken-2" text-color="white" label x-small v-else-if="item.status == 'unprocessed'">
										Unprocessed
									</v-chip>
								</td>

								<td>{{item.selected_numbers}}</td>
								<td>{{item.won_numbers}}</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<!-- bottom -->
				<v-card-actions class="grey lighten-4">
					<div class="text-center" style="width: 100%;">
						<v-pagination :length="userPlays.total_pages" :total-visible="5" v-model="currentPage" @input="getUserPlays()"></v-pagination>
					</div>
				</v-card-actions>
			</v-card>
		</v-col>
	</div>
</template>

<script>
import SearchInput from "../../components/SearchInput"

export default {
	name: "UserPlays",
	components: {
		SearchInput,
	},
	data: () => ({
		userPlaysLoading: false,
		userPlays: {
			page: 1,
			total_count: 0,
			total_pages: 1,
			rows: []
		},
		currentPage: 1,
		searchQ: '',
	}),
	computed: {
		cardLoading() {
			return this.userPlaysLoading ? 'blue lighten-3' : false;
		}
	},
	methods: {
		getUserPlays() {
			/* call the api */
			this.userPlaysLoading = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("page", this.currentPage);
			data.append("q", this.searchQ);
			data.append("user_id", this.$route.query.user_id || '');

			/* make call to server */
			this.$http.post("admin/games/getAllUserPlays", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.userPlays = result.data.data.userPlays;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.userPlaysLoading = false;
				})
		},
		searchUserPlays() {
			this.currentPage = 1;
			this.getUserPlays();
		},
	},
	created() {
		this.getUserPlays();
	}
};
</script>

<style>
.pointer {
	cursor: pointer;
}
</style>