<template>
	<div class="pt-6">
		<div class="row">
			<v-col cols="12">
				<v-card elevation="2">
					<v-card-title>
						Recent Users
					</v-card-title>

					<v-card-text>
						<LineChart :height="400" :data="users_chart_data" />
					</v-card-text>
				</v-card>
			</v-col>
		</div>
	</div>
</template>

<script>
import LineChart from "./LineChart";

export default {
	name: "Charts",
	components: {
		LineChart,
	},
	data: () => ({
		users_chart_data: {
			labels: [],
			datasets: [{
				data: [],
				lineTension: 0,
				label: "New users",
				borderColor: "#1e88e5",
				backgroundColor: "#1e88e5",
				borderWidth: 3,
				fill: false,
				pointHitRadius: 20
			}]
		},
	}),
	created() {
		for (var key in this.$parent.dashboardData.users_chart) {
			this.users_chart_data.labels.push(key);
			this.users_chart_data.datasets[0].data.push(this.$parent.dashboardData.users_chart[key]);
		}
	}
};
</script>

<style>
</style>