<template>
	<div>
		<v-row>

			<v-col cols="12" md="6" lg="4">
				<div class="small-box blue darken-1 white--text">
					<div class="inner">
						<h3>{{$parent.dashboardData.users_count}}</h3>
						<p>Total Users</p>
					</div>
					<div class="icon">
						<v-icon>mdi-account-multiple-outline</v-icon>
					</div>
					<router-link class="small-box-footer" to="/panel/users">
						View All <v-icon class="inherit-color">mdi-chevron-right</v-icon>
					</router-link>
				</div>
			</v-col>

			<v-col cols="12" md="6" lg="4">
				<div class="small-box green darken-1 white--text">
					<div class="inner">
						<h3>{{$parent.dashboardData.games_count}}</h3>
						<p>Total Games</p>
					</div>
					<div class="icon">
						<v-icon>mdi-dice-multiple</v-icon>
					</div>
					<router-link class="small-box-footer" to="/panel/games">
						View All <v-icon class="inherit-color">mdi-chevron-right</v-icon>
					</router-link>
				</div>
			</v-col>

			<v-col cols="12" md="12" lg="4">
				<div class="small-box orange darken-1 white--text">
					<div class="inner">
						<h3>{{$parent.dashboardData.user_plays_count}}</h3>
						<p>Total User Plays</p>
					</div>
					<div class="icon">
						<v-icon>mdi-view-list</v-icon>
					</div>
					<router-link class="small-box-footer" to="/panel/userPlays">
						View All <v-icon class="inherit-color">mdi-chevron-right</v-icon>
					</router-link>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: "SmallBoxes",

	components: {},

	data: () => ({
		//
	}),
};
</script>

<style>
.inherit-color {
	color: inherit !important;
}

.small-box {
	border-radius: 0.25rem;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
		0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
	position: relative;
	display: block;
}

.small-box > .inner {
	padding: 10px;
}

.small-box h3 {
	font-size: 38px;
	font-weight: bold;
	margin: 0 0 10px 0;
	white-space: nowrap;
	padding: 0;
}

.small-box:hover .icon {
	font-size: 95px;
}

.small-box .icon {
	transition: all 0.3s linear;
	position: absolute;
	top: -10px;
	right: 10px;
	z-index: 0;
	font-size: 90px;
	color: rgba(0, 0, 0, 0.15);
}

/* .small-box .icon {
	right: auto;
	left: 10px;
} */

.small-box .icon i {
	font-size: inherit;
	color: inherit !important;
}

.small-box > .small-box-footer {
	position: relative;
	text-align: center;
	padding: 3px 0;
	color: #ffffff;
	color: rgba(255, 255, 255, 0.8);
	display: block;
	z-index: 1;
	background: rgba(0, 0, 0, 0.1);
	text-decoration: none;
}
</style>