<template>
	<div>
		<v-col cols="12">
			<v-card elevation="2" :loading="cardLoading">
				<!-- top -->
				<v-card-title class="primary white--text" style="flex-wrap: nowrap;">
					<div>
						Gift Codes List
					</div>

					<v-btn class="ml-auto" color="white" depressed small outlined @click="openAddGiftCode()">
						Add Gift Code +
					</v-btn>
				</v-card-title>

				<!-- main -->
				<v-tabs v-model="selectedFilterTab" show-arrows>
					<v-tab v-for="item in filterTabItems" :key="item.value">{{item.title}}</v-tab>
				</v-tabs>

				<v-simple-table class="text-center text-no-wrap">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-center">#</th>
								<th class="text-center">Gift Code</th>
								<th class="text-center" @click="toggleSort()" style="cursor: pointer;">
									<v-icon size="14" v-if="sort == 'ASC'">mdi-sort-ascending</v-icon>
									<v-icon size="14" v-else-if="sort == 'DESC'">mdi-sort-descending</v-icon>
									Available
								</th>
								<th class="text-center">Gift Amount</th>
								<th class="text-center">Used Users</th>
								<th class="text-center">Remove</th>
							</tr>
						</thead>

						<tbody>
							<tr :class="{'red lighten-5' : item.available_count <= 0}" v-for="(item , index) in giftCodes.rows" :key="index">
								<td>{{item.id}}</td>
								<td>{{item.gift_code}}</td>
								<td>{{item.available_count}}</td>
								<td>{{item.gift_amount}}</td>
								<td>{{item.used_users}}</td>
								<td>
									<v-btn color="red darken-2 white--text" depressed small @click="openRemoveGiftCode(item)">Remove <v-icon right size="15">mdi-close</v-icon>
									</v-btn>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<!-- bottom -->
				<v-card-actions class="grey lighten-4">
					<div class="text-center" style="width: 100%;">
						<v-pagination :length="giftCodes.total_pages" :total-visible="5" v-model="currentPage" @input="getGiftCodes()"></v-pagination>
					</div>
				</v-card-actions>
			</v-card>
		</v-col>

		<AddGiftCode />
		<RemoveGiftCode />
	</div>
</template>

<script>
import SearchInput from "../../components/SearchInput"
import AddGiftCode from "./AddGiftCode"
import RemoveGiftCode from "./RemoveGiftCode"

export default {
	name: "GiftCodes",
	components: {
		SearchInput,
		AddGiftCode,
		RemoveGiftCode
	},
	data: () => ({
		giftCodesLoading: false,
		giftCodes: {
			page: 1,
			total_count: 0,
			total_pages: 1,
			rows: []
		},
		filterTabItems: [
			{ title: "10 BIXB", value: "10" },
			{ title: "20 BIXB", value: "20" },
			{ title: "50 BIXB", value: "50" },
			{ title: "100 BIXB", value: "100" },
		],
		selectedFilterTab: 0,
		currentPage: 1,
		searchQ: '',
		sort: "DESC",
		addGiftCode: false,
		removeGiftCode: false,
		removeGiftCodeData: {
			gift_code_id: null,
		},
	}),
	computed: {
		cardLoading() {
			return this.giftCodesLoading ? 'blue lighten-3' : false;
		},
		selectedFilterTabData() {
			if (this.filterTabItems[this.selectedFilterTab] == undefined) return {};
			return this.filterTabItems[this.selectedFilterTab];
		}
	},
	watch:{
		'selectedFilterTab': function(){
			this.getGiftCodes();
		}
	},
	methods: {
		getGiftCodes() {
			/* call the api */
			this.giftCodesLoading = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("page", this.currentPage);
			data.append("q", this.selectedFilterTabData.value);
			data.append("sort", this.sort);

			/* make call to server */
			this.$http.post("admin/transactions/getAllGiftCodes", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.giftCodes = result.data.data.gift_codes;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.giftCodesLoading = false;
				})
		},
		openAddGiftCode() {
			this.addGiftCode = true;
		},
		openRemoveGiftCode(item) {
			this.removeGiftCode = true;
			this.removeGiftCodeData.gift_code_id = item.id;
		},
		toggleSort(){
			if(this.sort == "ASC"){
				this.sort = "DESC";
			} else if(this.sort == "DESC"){
				this.sort = "ASC";
			}

			this.currentPage = 1;
			this.getGiftCodes();
		}
	},
	created() {
		this.getGiftCodes();
	}
};
</script>

<style>
.pointer {
	cursor: pointer;
}
</style>