<template>
	<div>
		<v-bottom-sheet v-model="$parent.editUser" inset>
			<v-sheet class="rounded-sheet" height="324px" style="overflow-y: auto !important;">
				<div class="px-10 py-7">
					<v-card-title class="pt-0 px-0 pb-7">
						<v-icon left color="black">mdi-pencil-circle</v-icon> Edit User
					</v-card-title>

					<v-form @submit.prevent="editUser()">
						<v-text-field type="text" label="Name" outlined v-model="editUserData.name"></v-text-field>
						<v-text-field type="tel" label="Wallet" outlined v-model="editUserData.wallet"></v-text-field>

						<v-btn type="submit" class="mx-auto d-block px-10" color="primary white--text" depressed :loading="editUserLoading" :disabled="!isDataFilled">Edit</v-btn>
					</v-form>
				</div>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	name: "EditUser",
	data: () => ({
		editUserLoading: false,
		editUserData: {
			name: '',
			wallet: '',
		}
	}),
	computed: {
		isDataFilled() {
			return true
		},
		isEditUserOpen() {
			return this.$parent.editUser;
		}
	},
	watch: {
		isEditUserOpen(val) {
			if (val) {
				this.editUserData.name = this.$parent.editUserData.name;
				this.editUserData.wallet = this.$parent.editUserData.wallet;
			}
		}
	},
	methods: {
		editUser() {
			this.editUserLoading = true;

			/* make data */
			let changed_data = {};
			Object.keys(this.editUserData).forEach(key => {
				var k = key;
				var val = this.editUserData[key];

				if (val != '' && this.$parent.editUserData[key] != val) {
					changed_data[k] = val;
				}
			});

			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("user_id", this.$parent.editUserData.id);
			data.append("data", JSON.stringify(changed_data));

			/* make call to server */
			this.$http.post("admin/users/updateUser", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.editUser = false;
						this.$parent.getUsers();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.editUserLoading = false;
				})
		}
	}
};
</script>

<style>
.rounded-sheet {
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	overflow: hidden !important;
}
</style>