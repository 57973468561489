<template>
	<div>
		<v-col cols="12">
			<v-card elevation="2" :loading="cardLoading">
				<!-- top -->
				<v-card-title class="primary white--text" style="flex-wrap: nowrap;">
					<div>
						Games List
					</div>

					<SearchInput v-model="searchQ" @submit="searchGames()" />
				</v-card-title>

				<!-- main -->
				<v-simple-table class="text-center text-no-wrap">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-center">#</th>
								<th class="text-center">Start Time</th>
								<th class="text-center">Closed Time</th>
								<th class="text-center">Status</th>
								<th class="text-center">User Plays</th>
								<th class="text-center">Total Received</th>
								<th class="text-center">Total Paid</th>
								<th class="text-center">Numbers</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(item , index) in games.rows" :key="index">
								<td>{{item.id}}</td>
								<td>{{item.human_start_time}}</td>
								<td>{{item.human_closed_time}}</td>

								<td>
									<v-chip color="green darken-2" text-color="white" label x-small v-if="item.status == 'open'">
										Open
									</v-chip>
									<v-chip color="blue darken-2" text-color="white" label x-small v-else-if="item.status == 'closed'">
										Closed
									</v-chip>
								</td>

								<td>{{item.user_plays_count}}</td>
								<td>{{item.total_received}}</td>
								<td>{{item.total_paid}}</td>
								<td>{{item.numbers}}</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<!-- bottom -->
				<v-card-actions class="grey lighten-4">
					<div class="text-center" style="width: 100%;">
						<v-pagination :length="games.total_pages" :total-visible="5" v-model="currentPage" @input="getGames()"></v-pagination>
					</div>
				</v-card-actions>
			</v-card>
		</v-col>
	</div>
</template>

<script>
import SearchInput from "../../components/SearchInput"

export default {
	name: "Games",
	components: {
		SearchInput,
	},
	data: () => ({
		gamesLoading: false,
		games: {
			page: 1,
			total_count: 0,
			total_pages: 1,
			rows: []
		},
		currentPage: 1,
		searchQ: '',
	}),
	computed: {
		cardLoading() {
			return this.gamesLoading ? 'blue lighten-3' : false;
		}
	},
	methods: {
		getGames() {
			/* call the api */
			this.gamesLoading = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("page", this.currentPage);
			data.append("q", this.searchQ);

			/* make call to server */
			this.$http.post("admin/games/getAllGames", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.games = result.data.data.games;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.gamesLoading = false;
				})
		},
		searchGames() {
			this.currentPage = 1;
			this.getGames();
		},
		suspendUser(user_id, index, suspend) {
			/* call the api */
			this.suspendLoading = true;
			this.suspendLoadingIndex = index;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("user_id", user_id);
			data.append("suspend", suspend);

			/* make call to server */
			this.$http.post("admin/games/suspendUser", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.games.rows[index] = result.data.data.user;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.suspendLoading = false;
				})
		},
	},
	created() {
		this.getGames();
	}
};
</script>

<style>
.pointer {
	cursor: pointer;
}
</style>