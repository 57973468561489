<template>
	<div>
		<v-col cols="12">
			<v-card elevation="2" :loading="cardLoading">
				<!-- top -->
				<v-card-title class="primary white--text" style="flex-wrap: nowrap;">
					<div>
						Wallet Logs List
					</div>

					<SearchInput v-model="searchQ" @submit="searchWalletLogs()" />
				</v-card-title>

				<!-- main -->
				<v-simple-table class="text-center text-no-wrap">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-center">#</th>
								<th class="text-center">User</th>
								<th class="text-center">Username</th>
								<th class="text-center">Amount</th>
								<th class="text-center">Description</th>
								<th class="text-center">Date</th>
							</tr>
						</thead>

						<tbody>
							<tr :class="{'red lighten-5' : item.amount < 0 ,  'green lighten-5' : item.amount > 0}" v-for="(item , index) in walletLogs.rows" :key="index">
								<td>{{item.id}}</td>
								<td>#{{item.user_id}}</td>
								<td>{{item.username}}</td>
								<td>{{item.amount}}</td>
								<td>{{item.description}}</td>
								<td>{{item.human_date}}</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<!-- bottom -->
				<v-card-actions class="grey lighten-4">
					<div class="text-center" style="width: 100%;">
						<v-pagination :length="walletLogs.total_pages" :total-visible="5" v-model="currentPage" @input="getWalletLogs()"></v-pagination>
					</div>
				</v-card-actions>
			</v-card>
		</v-col>
	</div>
</template>

<script>
import SearchInput from "../../components/SearchInput"

export default {
	name: "WalletLogs",
	components: {
		SearchInput,
	},
	data: () => ({
		walletLogsLoading: false,
		walletLogs: {
			page: 1,
			total_count: 0,
			total_pages: 1,
			rows: []
		},
		currentPage: 1,
		searchQ: '',
	}),
	computed: {
		cardLoading() {
			return this.walletLogsLoading ? 'blue lighten-3' : false;
		}
	},
	methods: {
		getWalletLogs() {
			/* call the api */
			this.walletLogsLoading = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("page", this.currentPage);
			data.append("q", this.searchQ);

			/* make call to server */
			this.$http.post("admin/users/getAllWalletLogs", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.walletLogs = result.data.data.walletLogs;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.walletLogsLoading = false;
				})
		},
		searchWalletLogs() {
			this.currentPage = 1;
			this.getWalletLogs();
		},
	},
	created() {
		this.getWalletLogs();
	}
};
</script>

<style>
.pointer {
	cursor: pointer;
}
</style>