<template>
	<div>
		<div class="text-center py-5" v-if="dashboardLoading">
			<v-progress-circular indeterminate color="primary"></v-progress-circular>
		</div>

		<v-col cols="12" v-else>
			<SmallBoxes />
			<Charts/>
		</v-col>
	</div>
</template>

<script>
import SmallBoxes from "./SmallBoxes";
import Charts from "./Charts";


export default {
	name: "Dashboard",
	components: {
		SmallBoxes,
		Charts
	},
	data: () => ({
		dashboardLoading: false,
		dashboardData: {},
	}),
	methods: {
		getDashboardData() {
			/* call the api */
			this.dashboardLoading = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);

			/* make call to server */
			this.$http.post("admin/general/getDashboard", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.dashboardData = result.data.data;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.dashboardLoading = false;
				})
		},
	},
	created() {
		this.getDashboardData();
	}
};
</script>

<style>
</style>