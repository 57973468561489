<template>
	<div>
		<v-col cols="12">
			<v-card elevation="2" :loading="cardLoading">
				<!-- top -->
				<v-card-title class="primary white--text" style="flex-wrap: nowrap;">
					<div>
						Users List
					</div>

					<SearchInput v-model="searchQ" @submit="searchUsers()" />
				</v-card-title>

				<!-- main -->
				<v-simple-table class="text-center text-no-wrap">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-center">#</th>
								<th class="text-center">Name</th>
								<th class="text-center">Username</th>
								<th class="text-center">Password</th>
								<th class="text-center">Wallet</th>
								<th class="text-center">Account Status</th>
								<th class="text-center">Register Date</th>
								<th class="text-center" width="50px"></th>
								<th class="text-center" width="50px"></th>
								<th class="text-center" width="50px"></th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(item , index) in users.rows" :key="index">
								<td>{{item.id}}</td>
								<td>{{item.name}}</td>
								<td>{{item.username}}</td>
								<td>{{item.password}}</td>
								<td>{{item.wallet}}</td>
								<td>
									<v-chip color="green darken-2" text-color="white" label x-small v-if="item.suspended == 0">
										Active
									</v-chip>
									<v-chip color="red darken-2" text-color="white" label x-small v-if="item.suspended == 1">
										Suspended
									</v-chip>
								</td>
								<td>{{item.human_registered_at}}</td>
								<td>
									<v-btn color="grey darken-2 white--text" depressed small @click="openEditUser(item)">Edit</v-btn>
								</td>
								<td>
									<v-btn color="red darken-2 white--text" depressed small outlined width="115px" v-if="item.suspended == 0" :loading="suspendLoading && suspendLoadingIndex === index" @click="suspendUser(item.id , index, 1)">
										Suspend <v-icon class="mx-1" size="15px">mdi-alert</v-icon>
									</v-btn>
									<v-btn color="green darken-2 white--text" depressed small outlined width="115px" v-if="item.suspended == 1" :loading="suspendLoading && suspendLoadingIndex === index" @click="suspendUser(item.id , index, 0)">
										Activate <v-icon class="mx-1" size="15px">mdi-account-reactivate-outline</v-icon>
									</v-btn>
								</td>
								<td>
									<v-btn color="primary" depressed small :to="'/panel/userPlays?user_id=' + item.id">Plays</v-btn>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<!-- bottom -->
				<v-card-actions class="grey lighten-4">
					<div class="text-center" style="width: 100%;">
						<v-pagination :length="users.total_pages" :total-visible="5" v-model="currentPage" @input="getUsers()"></v-pagination>
					</div>
				</v-card-actions>
			</v-card>
		</v-col>

		<EditUser/>
	</div>
</template>

<script>
import SearchInput from "../../components/SearchInput"
import EditUser from "./EditUser"

export default {
	name: "Users",
	components: {
		SearchInput,
		EditUser
	},
	data: () => ({
		usersLoading: false,
		users: {
			page: 1,
			total_count: 0,
			total_pages: 1,
			rows: []
		},
		currentPage: 1,
		searchQ: '',
		suspendLoading: false,
		suspendLoadingIndex: 0,
		editUser: false,
		editUserData: {},
	}),
	computed: {
		cardLoading() {
			return this.usersLoading ? 'blue lighten-3' : false;
		}
	},
	methods: {
		getUsers() {
			/* call the api */
			this.usersLoading = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("page", this.currentPage);
			data.append("q", this.searchQ);

			/* make call to server */
			this.$http.post("admin/users/getAllUsers", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.users = result.data.data.users;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.usersLoading = false;
				})
		},
		searchUsers() {
			this.currentPage = 1;
			this.getUsers();
		},
		suspendUser(user_id, index, suspend) {
			/* call the api */
			this.suspendLoading = true;
			this.suspendLoadingIndex = index;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("user_id", user_id);
			data.append("suspend", suspend);

			/* make call to server */
			this.$http.post("admin/users/suspendUser", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.users.rows[index] = result.data.data.user;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.suspendLoading = false;
				})
		},
		openEditUser(user){
			this.editUser = true;
			this.editUserData = user;
		}
	},
	created() {
		this.getUsers();
	}
};
</script>

<style>
.pointer {
	cursor: pointer;
}
</style>