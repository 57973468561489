<template>
	<div>
		<v-card elevation="2">
			<!-- top -->
			<v-card-title class="primary white--text mb-3">
				Change Password
			</v-card-title>

			<!-- main -->
			<v-card-text>
				To change the password, please enter the current password and the new password.

				<v-form class="mt-5" @submit.prevent="changePassword()">
					<v-text-field type="password" label="Current Password" outlined v-model="currentPass"></v-text-field>
					<v-text-field type="password" label="New Password" outlined v-model="newPass"></v-text-field>
					<v-text-field type="password" label="Confirm Password" outlined v-model="newPassRepeat"></v-text-field>

					<v-btn type="submit" color="primary" depressed class="d-block mx-auto mb-3" :disabled="!isDataFilled" :loading="changePasswordLoading">Change Password</v-btn>
				</v-form>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
export default {
	name: "ChangePassword",
	data: () => ({
		changePasswordLoading: false,
		currentPass: '',
		newPass: '',
		newPassRepeat: ''
	}),
	computed: {
		isDataFilled() {
			if (this.currentPass == '') return false;
			if (this.newPass == '') return false;
			if (this.newPassRepeat == '') return false;

			return true;
		},
		formError() {
			if (this.newPass != this.newPassRepeat) return "Confirm Password doesn't match!";
			return '';
		}
	},
	methods: {
		changePassword() {
			if (this.formError == '') {
				/* call the api */
				this.changePasswordLoading = true;

				/* make data */
				var data = new FormData();
				data.append("token", this.$store.state.Admin.admin_token);
				data.append("current_pass", this.currentPass);
				data.append("new_pass", this.newPass);
				data.append("new_pass_repeat", this.newPassRepeat);

				/* make call to server */
				this.$http.post("admin/admins/changePassword", data)
					.then((result) => {
						if (result.data.status == "ok") {
							this.resetForm();
							this.$swal({
								icon: "success",
								text: 'Your password has been changed!',
								confirmButtonText: "Fine",
							});
						} else {
							if(result.data.error == 'Current password is incorrect!') result.data.error = "Current Password is incorrect!";
							this.$swal({
								icon: "error",
								text: result.data.error,
								confirmButtonText: "Fine",
							});
						}
					})
					.catch((error) => {
						this.$swal({
							icon: "error",
							text: error,
							confirmButtonText: "Fine",
						});
					})
					.finally(() => {
						this.changePasswordLoading = false;
					})
			} else {
				this.$swal({
					icon: "error",
					text: this.formError,
					confirmButtonText: "Fine",
				});
			}
		},
		resetForm(){
			this.currentPass = '';
			this.newPass = '';
			this.newPassRepeat = '';
		}
	}
};
</script>

<style>
</style>