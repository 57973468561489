<template>
	<div>
		<v-col cols="12">
			<v-card elevation="2" :loading="cardLoading">
				<!-- top -->
				<v-card-title class="primary white--text" style="flex-wrap: nowrap;">
					<div>
						Withdraws List
					</div>

					<SearchInput v-model="searchQ" @submit="searchWithdraws()" />
				</v-card-title>

				<!-- main -->
				<v-simple-table class="text-center text-no-wrap">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-center">#</th>
								<th class="text-center">User id</th>
								<th class="text-center">Amount</th>
								<th class="text-center">Address</th>
								<th class="text-center">status</th>
								<th class="text-center">Date</th>
								<th class="text-center" width="50px"></th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="(item , index) in withdraws.rows" :key="index">
								<td>{{item.id}}</td>
								<td>{{item.user_id}}</td>
								<td>{{item.amount}}</td>
								<td>{{item.address}}</td>
								<td>
									<v-chip color="blue darken-2" text-color="white" label x-small v-if="item.status == 'pending'">
										Pending
									</v-chip>
									<v-chip color="blue darken-2" text-color="white" label x-small v-if="item.status == 'accepted'">
										Accepted
									</v-chip>
									<v-chip color="green darken-2" text-color="white" label x-small v-if="item.status == 'completed'">
										Completed
									</v-chip>
									<v-chip color="red darken-2" text-color="white" label x-small v-if="item.status == 'error'">
										Error
									</v-chip>
								</td>
								<td>{{item.human_created_at}}</td>
								<td>
									<v-btn color="green darken-2 white--text" depressed small @click="openAcceptWithdraw(item)" :disabled="item.status != 'pending' && item.status != 'error'">Accept <v-icon right size="15">mdi-check</v-icon>
									</v-btn>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<!-- bottom -->
				<v-card-actions class="grey lighten-4">
					<div class="text-center" style="width: 100%;">
						<v-pagination :length="withdraws.total_pages" :total-visible="5" v-model="currentPage" @input="getWithdraws()"></v-pagination>
					</div>
				</v-card-actions>
			</v-card>
		</v-col>

		<AcceptWithdraw />
	</div>
</template>

<script>
import SearchInput from "../../components/SearchInput"
import AcceptWithdraw from "./AcceptWithdraw"

export default {
	name: "Withdraws",
	components: {
		SearchInput,
		AcceptWithdraw
	},
	data: () => ({
		withdrawsLoading: false,
		withdraws: {
			page: 1,
			total_count: 0,
			total_pages: 1,
			rows: []
		},
		currentPage: 1,
		searchQ: '',
		acceptWithdraw: false,
		acceptWithdrawData: {},
	}),
	computed: {
		cardLoading() {
			return this.withdrawsLoading ? 'blue lighten-3' : false;
		}
	},
	methods: {
		getWithdraws() {
			/* call the api */
			this.withdrawsLoading = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("page", this.currentPage);
			data.append("q", this.searchQ);

			/* make call to server */
			this.$http.post("admin/transactions/getAllWithdraws", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.withdraws = result.data.data.withdraws;
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.withdrawsLoading = false;
				})
		},
		searchWithdraws() {
			this.currentPage = 1;
			this.getWithdraws();
		},
		openAcceptWithdraw(withdraw) {
			this.acceptWithdrawData = withdraw;
			this.acceptWithdraw = true;
		}
	},
	created() {
		this.getWithdraws();
	}
};
</script>

<style>
.pointer {
	cursor: pointer;
}
</style>