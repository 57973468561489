import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login'
import Logout from '../views/Logout'
import Panel from '../views/Panel'

import Dashboard from '../views/dashboard/Dashboard'
import Users from '../views/users/Users'
import Games from '../views/games/Games'
import UserPlays from '../views/userPlays/UserPlays'
import Deposits from '../views/deposits/Deposits'
import Withdraws from '../views/withdraws/Withdraws'
import WalletLogs from '../views/walletLogs/WalletLogs'
import GiftCodes from '../views/giftCodes/GiftCodes'
import Settings from '../views/settings/Settings'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/panel',
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/logout',
		name: 'Logout',
		component: Logout
	},
	{
		path: '/panel',
		name: 'Panel',
		redirect: '/panel/dashboard',
		component: Panel,
		children: [
			{
				path: 'dashboard',
				name: 'Dashboard',
				component: Dashboard
			},
			{
				path: 'users',
				name: 'Users',
				component: Users
			},
			{
				path: 'games',
				name: 'Games',
				component: Games
			},
			{
				path: 'userPlays',
				name: 'UserPlays',
				component: UserPlays
			},
			{
				path: 'deposits',
				name: 'Deposits',
				component: Deposits
			},
			{
				path: 'withdraws',
				name: 'Withdraws',
				component: Withdraws
			},
			{
				path: 'walletLogs',
				name: 'WalletLogs',
				component: WalletLogs
			},
			{
				path: 'giftCodes',
				name: 'GiftCodes',
				component: GiftCodes
			},
			{
				path: 'settings',
				name: 'Settings',
				component: Settings
			},
		]
	},

]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
