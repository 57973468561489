<template>
	<div>
		<v-bottom-sheet v-model="$parent.acceptWithdraw" inset>
			<v-sheet class="rounded-sheet" height="230px">
				<div class="px-10 py-7">
					<v-card-title class="pt-0 px-0 pb-3 green--text text--darken-4">
						<v-icon left color="green darken-4">mdi-check</v-icon> Accept Withdraw 
					</v-card-title>

					<v-card-text>
						Are you sure about this?
					</v-card-text>

					<v-btn class="mt-2" color="green darken-4 white--text" block depressed @click="acceptWithdraw($parent.acceptWithdrawData.id)" :loading="acceptWithdrawLoading">Accept</v-btn>
					<v-btn class="mt-4" color="grey darken-4" block outlined @click="$parent.acceptWithdraw = false">Nevermind</v-btn>
				</div>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	name: "AcceptWithdraw",
	data: () => ({
		acceptWithdrawLoading: false,
	}),
	methods: {
		acceptWithdraw(withdraw_id) {
			/* call the api */
			this.acceptWithdrawLoading = true;

			/* make data */
			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("withdraw_id", withdraw_id);

			/* make call to server */
			this.$http.post("admin/transactions/acceptWithdraw", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.getWithdraws();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.$parent.acceptWithdraw = false;
					this.acceptWithdrawLoading = false;
				})
		}
	}
};
</script>

<style>
.rounded-sheet {
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	overflow: hidden !important;
}
</style> 