<template>
	<div>
		<v-bottom-sheet v-model="$parent.addGiftCode" inset>
			<v-sheet class="rounded-sheet" height="410px" style="overflow-y: auto !important;">
				<div class="px-10 py-7">
					<v-card-title class="pt-0 px-0 pb-7">
						<v-icon left color="black">mdi-plus-circle</v-icon> Add Gift Code
					</v-card-title>

					<v-form @submit.prevent="addGiftCode()">
						<v-text-field type="text" label="Gift Code" outlined v-model="addGiftCodeData.gift_code"></v-text-field>
						<v-text-field type="tel" label="Available Count" outlined v-model="addGiftCodeData.available_count"></v-text-field>
						<v-text-field type="tel" label="Gift Amount" outlined v-model="addGiftCodeData.gift_amount" disabled></v-text-field>

						<v-btn type="submit" class="mx-auto d-block px-10" color="primary white--text" depressed :loading="addGiftCodeLoading" :disabled="!isDataFilled">Add</v-btn>
					</v-form>
				</div>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	name: "GiftCode",
	data: () => ({
		addGiftCodeLoading: false,
		addGiftCodeData: {
			gift_code: '',
			available_count: '',
			gift_amount: '',
		}
	}),
	computed: {
		isDataFilled() {
			if (this.addGiftCodeData.gift_code == '') return false;
			if (this.addGiftCodeData.available_count == '') return false;
			if (this.addGiftCodeData.gift_amount == '') return false;

			return true;
		},
		isGiftCodeOpen() {
			return this.$parent.addGiftCode;
		}
	},
	watch:{
		'$parent.addGiftCode': function(new_val){
			if(new_val){
				this.addGiftCodeData.gift_code = this.$global.randomGiftCode();
				this.addGiftCodeData.available_count = 1;
				this.addGiftCodeData.gift_amount = this.$parent.selectedFilterTabData.value
			}
		}
	},
	methods: {
		addGiftCode() {
			this.addGiftCodeLoading = true;

			var data = new FormData();
			data.append("token", this.$store.state.Admin.admin_token);
			data.append("gift_code", this.addGiftCodeData.gift_code);
			data.append("available_count", this.addGiftCodeData.available_count);
			data.append("gift_amount", this.addGiftCodeData.gift_amount);

			/* make call to server */
			this.$http.post("admin/transactions/addGiftCode", data)
				.then((result) => {
					if (result.data.status == "ok") {
						this.$parent.addGiftCode = false;
						this.resetForm();
						this.$parent.getGiftCodes();
					} else {
						this.$swal({
							icon: "error",
							text: result.data.error,
							confirmButtonText: "Okay",
						});
					}
				})
				.catch((error) => {
					this.$swal({
						icon: "error",
						text: error,
						confirmButtonText: "Okay",
					});
				})
				.finally(() => {
					this.addGiftCodeLoading = false;
				})
		},
		resetForm() {
			this.addGiftCodeData = {
				gift_code: '',
				available_count: '',
				gift_amount: '',
			}
		}
	}
};
</script>

<style>
.rounded-sheet {
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	overflow: hidden !important;
}
</style>